<template>
  <div class="container-fluid d-flex align-items-stretch h-100 flex-fill">
    <aside class="d-flex flex-column">
      <TeamHeader :team_name="info.name" :image="info.logo_url" />

      <MapNavigation @select_map="select_map($event)" :available-map-ids="teamMapIds" />
    </aside>

    <main class="flex-fill mt-4 mb-4">
      <div class="team-headline d-flex justify-content-between align-items-end mb-4">
        <div class="">
          <h1>
            {{ info.name }} <small v-if="info.region">{{ info.region }}</small>
          </h1>

          <div class="d-flex align-items-baseline">
            <h6 class="mr-1 text-muted">Members:</h6>

            <template v-if="!hideBrokenStuff">
              <router-link
                v-for="member in sortedMembers"
                class="ml-2"
                :class="!member.is_active ? 'member-inactive' : ''"
                :key="member.id"
                :to="`/player/${member.id}/stats/${$route.params.map_id}`"
              >
                {{ member.name }}
              </router-link>
            </template>
            <template v-else>
              <div
                v-for="member in sortedMembers"
                class="ml-2"
                :class="!member.is_active ? 'member-inactive' : ''"
                :key="member.id"
              >
                {{ member.name }}
              </div>
            </template>
          </div>
        </div>

        <b-nav v-if="mapId" pills align="center">
          <b-button
            variant="outline-primary"
            :active="$route.name === 'TeamReports'"
            :to="`/team/${$route.params.team_id}/reports/${mapId}`"
          >
            Reports
          </b-button>
          <b-button
            variant="outline-primary"
            :active="$route.name === 'TeamStats'"
            :to="`/team/${$route.params.team_id}/stats/${mapId}`"
          >
            Stats
          </b-button>
          <b-button
            variant="outline-primary"
            :active="$route.name === 'TeamMatches'"
            :to="`/team/${$route.params.team_id}/matches/${mapId}`"
          >
            Matches
          </b-button>
        </b-nav>
      </div>

      <router-view name="team" />
    </main>
  </div>
</template>

<script>
import px from 'vue-types'
import { mapGetters } from 'vuex'

import MapNavigation from '@/components/UI/MapNavigation.vue'
import TeamHeader from '@/components/UI/TeamHeader.vue'
import mixpanel from '@/mixpanel'
import stringCompare from '@/utils/stringCompare.js'

export default {
  name: 'TeamMain',
  components: {
    TeamHeader,
    MapNavigation,
  },
  props: {
    info: px.object.def({}),
    generalStats: px.object.def({}),
  },
  data() {
    return {
      mapId: null,
    }
  },
  mounted() {
    this.mapId = this.$route.params.map_id || this.default_map_id()

    if (!this.$route.params.map_id) {
      this.$router.replace({
        name: 'TeamStats',
        params: {
          team_id: this.$route.params.team_id,
          map_id: this.default_map_id(),
        },
      })
    }
  },

  beforeRouteUpdate(to, from, next) {
    if (!to.params.map_id) {
      this.$router.replace({
        name: from.name,
        params: {
          team_id: to.params.team_id,
          map_id: this.default_map_id(),
        },
      })
    }

    const [map_name] = this.maps.filter(m => m.id === to.params.map_id).map(m => m.name)

    mixpanel.track_team_sub_page(this.team_name, to.name, map_name)

    next()
  },

  computed: {
    ...mapGetters({
      maps: 'static/maps',
    }),
    sortedMembers() {
      return [...this.info.members_expand].sort((a, b) =>
        a.is_active === b.is_active ? stringCompare()(a.name, b.name) : a.is_active ? -1 : 1
      )
    },
    teamMapIds() {
      return this.generalStats?.maps_stats?.map(map => map.map) ?? []
    },
  },

  methods: {
    default_map_id() {
      if (this.$route.query.map_ids) {
        if (typeof this.$route.query.map_ids === 'string') {
          return this.$route.query.map_ids
        } else {
          return this.$route.query.map_ids[0]
        }
      }
      const [ascent_id] = this.maps.map(m => m.id)
      return ascent_id
    },

    select_map(map_id) {
      if (map_id === this.$route.params.map_id) {
        return
      }

      const name = this.$route.name
      const params = {
        ...this.$route.params,
        map_id,
      }

      this.mapId = map_id

      this.$router.push({
        name,
        params,
      })

      const [map_name] = this.maps.filter(m => m.id === map_id).map(m => m.name)

      mixpanel.track_team_map(this.team_name, map_name)
    },
  },
}
</script>

<style lang="scss" scoped>
aside {
  margin: 1rem;
  margin-left: 0;
  width: 16vw;
}

.team-headline {
  border-bottom: 2px solid $primary;

  .nav {
    margin-bottom: -1px;
  }
}

.member-inactive {
  color: $gray-600;
}

::v-deep {
  .filters--item__teams,
  .filters--item__agents {
    .filters--item--title + div {
      padding-bottom: 30px;
    }
  }
}
</style>

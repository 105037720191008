<template>
  <header
    class="d-flex flex-column justify-content-center align-items-center mb-2"
    :style="{
      'background-image': `url(${selected_map_url})`,
      'border-width': $route.params.map_id ? '0' : '.5rem',
    }"
  >
    <img :src="image" class="team-logo" :alt="team_name" @error="set_default_image" />
    <h5 v-if="player_name">
      {{ player_name }}
    </h5>
    <h5 v-else>
      {{ team_name }}
    </h5>
  </header>
</template>

<script>
import px from 'vue-types'
import { mapGetters } from 'vuex'

export default {
  props: {
    image: px.string,
    team_name: px.string,
    player_name: px.string,
  },

  computed: {
    ...mapGetters({
      maps: 'static/maps',
    }),

    selected_map_url() {
      const [map_name] = this.maps.filter(map => map.id === this.$route.params.map_id).map(map => map.name)

      return `/images/maps/${(map_name || '').toLowerCase()}.jpg`
    },
  },

  methods: {
    set_default_image(e) {
      e.target.src = '/images/teams/no-logo.png'
    },
  },
}
</script>

<style lang="scss" scoped>
header {
  position: relative;
  height: 9vw;
  border: 0.5rem dashed lighten($body-bg, 10%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgb(0 0 0 / 40%);
  }

  img,
  h5 {
    z-index: 1;
  }

  img {
    max-width: 35%;
  }
}
</style>

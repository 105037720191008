<template>
  <b-button-group vertical class="mb-2">
    <b-button
      v-for="map in maps"
      :key="map.id"
      variant="outline-primary"
      :class="`${$route.params.map_id === map.id ? 'active' : ''} ${
        !availableMapIds.includes(map.id) ? 'no-data' : ''
      }`"
      @click="$emit('select_map', map.id)"
    >
      {{ map.name }}
    </b-button>
  </b-button-group>
</template>

<script>
import px from 'vue-types'
import { mapGetters } from 'vuex'

export default {
  props: {
    availableMapIds: px.arrayOf(px.string).def([]),
  },
  computed: {
    ...mapGetters({
      maps: 'static/maps',
    }),
  },
}
</script>

<style lang="scss" scoped>
.no-data {
  color: $dark;
}
</style>

<template>
  <ApiLoadingController :fetch="fetch" :params="teamId">
    <template #default="{ data }">
      <TeamMain v-bind="data"></TeamMain>
    </template>
  </ApiLoadingController>
</template>

<script>
import { getTeamInfo, getTeamGeneralStats } from '@/api/teams.js'
import ApiLoadingController from '@/components/controllers/ApiLoadingController.vue'
import TeamMain from '@/views/TeamNew/TeamMain.vue'

export default {
  name: 'TeamPage',
  components: { ApiLoadingController, TeamMain },
  computed: {
    teamId() {
      return this.$route.params.team_id
    },
  },
  data() {
    return {
      teamData: {
        info: null,
        generalStats: null,
      },
    }
  },
  provide() {
    return { teamData: this.teamData }
  },
  methods: {
    async fetch(teamId) {
      this.teamData.info = await getTeamInfo(teamId)
      this.teamData.generalStats = await getTeamGeneralStats(teamId)
      return { ...this.teamData }
    },
  },
}
</script>
